import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { t } = this.props;
      return (
       <div>
         <footer>
          <div className="container">
            <img className="footer_logo" src="img/logo.png" alt="" />
            <p className="copy_text">&copy; {t('mainContent.Copyright_Text')}</p>
          </div>

        </footer>

        <div className="overlay">
          <div className="overlayDoor"></div>
          <div className="overlayContent">
            <img src="img/logo.png" alt="" />
            <p>Loading.....</p>
          </div>
        </div>
        {/* <a id="return-to-top" className="scroll-down"><span>{t('mainContent.Go_To_Top')}</span></a> */}
       </div>
      );
    }
  }

  //export default Footer;
  export default withTranslation()(Footer);