import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container">
            <div className="d-flex">
              <div className="flex-fill">
                <a href="/">
                  <img className="logo" src="img/light-logo.png" alt="" />
                </a>
              </div>
              <div className="flex-fill">
                <p className="mb-0 text-end"><strong>Current Polkabot Rate : <span className="color1">{parseFloat(this.props.currentPrice).toFixed(8)} BNB</span></strong></p>
              </div>
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;