import React,{useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';



function TopHeader(props){
  const {isHome,address} = props;

  const { t, i18n } = useTranslation();

  let DefaultLan = localStorage.getItem('DefaultLan','English');
  if(!DefaultLan){
    DefaultLan = 'English';
  }
  let DefaultStrLan = localStorage.getItem('DefaultStrLan','en');
  if(!DefaultStrLan){
    DefaultStrLan = 'en';
  }
  let DefaultLanIcon = localStorage.getItem('DefaultLanIcon','img/language/en.svg');
  if(!DefaultLanIcon){
    DefaultLanIcon = 'img/language/en.svg';
  }
  const [currentLanguage, setCurrentLanguage] = useState(DefaultLan);
  const [currentSrtLanguage, setCurrentSrtLanguage] = useState(DefaultStrLan);
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(DefaultLanIcon);

  useEffect(() => {
    i18n.changeLanguage(DefaultStrLan);
  },[DefaultStrLan]);


  const changeLanguage = function(event){
      if(event.target.closest("a")){
        let el = event.target.closest("a");
        let lang = el.getAttribute("data-lang");
        i18n.changeLanguage(lang);

        if(lang == "en"){
          saveLanguage("en", "English", "img/language/en.svg");

        }else if(lang == "ru"){
          saveLanguage("ru", "Русский", "img/language/ru.svg");

        }else if(lang == "zh"){
          saveLanguage("zh", "中国", "img/language/zh.svg");

        }else if(lang == "es"){
          saveLanguage("es", "Español", "img/language/es.svg");


        }else if(lang == "id"){
          saveLanguage("id", "Indonesian", "img/language/id.svg");

        }else if(lang == "hi"){
          saveLanguage("hi", "हिंदी", "img/language/hi.svg");

        }else if(lang == "ar"){
          saveLanguage("ar", "عربى", "img/language/ara.svg");


        }else if(lang == "vi"){
          saveLanguage("vi", "Việt Nam", "img/language/vi.svg");
        }
        else if(lang == "it"){
          saveLanguage("it", "Italian", "img/language/it.svg");
        }
        else if(lang == "pt"){
          saveLanguage("pt", "português", "img/language/pt.svg");
        }
        else if(lang == "ja"){
          saveLanguage("ja", "日本", "img/language/ja.svg");
        }
        else if(lang == "ko"){
          saveLanguage("ko", "한국어", "img/language/ko.svg");
        }
      }

  }

  const saveLanguage = function(str, lang, iconLink){
    setCurrentSrtLanguage(str);
    setCurrentLanguage(lang);
    setCurrentLanguageIcon(iconLink);

    localStorage.setItem('DefaultLan',lang);
    localStorage.setItem('DefaultStrLan', str);
    localStorage.setItem('DefaultLanIcon',iconLink);
  }
  return (
    <header>
      <div className='header_bg'>
        <div className="container">
          <div className="d-flex">
            <div className="flex-fill">
              <a href="/">
                <img className="logo" src="img/logo.png" alt="" />
              </a>
            </div>
            {/* <div className="flex-fill">
              <p className="mb-0 text-right"><strong>{t('header.Current_Polkabot_Rate')} : <span className="color1">{parseFloat(props.currentPrice).toFixed(8)} {t('mainContent.BNB')}</span></strong></p>
            </div> */}

            <div className="language_abs disp_inline">
              <div className="dropdown">
                <button className="dropbtn"><img className="main_lang_flag" alt="" src={currentLanguageIcon} />  {currentLanguage}</button>
                <div className="dropdown-content" onClick={changeLanguage}>
                  <a data-lang="en" style={{display: (currentSrtLanguage == 'en') ? "none" : "block"}} ><img alt="" src="img/language/en.svg" />  English</a>
                  <a data-lang="zh" style={{display: (currentSrtLanguage == 'zh') ? "none" : "block"}} ><img alt="" src="img/language/zh.svg" />  中国</a>
                  <a data-lang="ko" style={{display: (currentSrtLanguage == 'ko') ? "none" : "block"}} ><img alt="" src="img/language/ko.svg" />  한국어</a>
                  <a data-lang="ru" style={{display: (currentSrtLanguage == 'ru') ? "none" : "block"}} ><img alt="" src="img/language/ru.svg" />  Русский</a>
                  <a data-lang="vi" style={{display: (currentSrtLanguage == 'vi') ? "none" : "block"}} ><img alt="" src="img/language/vi.svg" />  Việt Nam</a>
                </div>
              </div>
            </div>
          </div>        
        </div>
      </div>    
    </header>    
  )
}

export default  TopHeader;