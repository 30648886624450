import React, { Component } from 'react';
import Frame from "../../components/frame";
import TopHeader from '../../components/TopHeader';
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import BillionsBNB from "../../contracts/BillionsBNB.json";
import getWeb3 from "../../getWeb3";
import { BigNumber } from "bignumber.js";
import moment from 'moment/moment';
//import CountdownTimer from '../countdown.js';
// Internationalization
import { withTranslation } from 'react-i18next';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      YourAddress:'-----------',
      YourAddressfull:null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},      
      user_referer:'-----------',
      userBalanceOf:0,
      currentPrice:0,
      currentSellPrice:0,
      balance:0,
      balanceEthVal:0,
      amount:'',
      totalAmount:'',
      availableStake:0,
      availableStakeBnb:0,
      staking:0,
      totalTokenSupply:0,
      table_data:[],
      per:0,
      isBuy: true,
      TokenAmount:'',
      sponsorList:[],
      getUserTotalDividends:0,
      getUserTotalDividendsBnb:0,
      get_polkabot_amount:0,
      get_bnb_amount:0,
      UsdToBnb:0,
      referrerBonus:0,
      current_timestamp: Math.floor(Date.now() / 1000),
      lockin_period_end_time: null,
      systemPoolAmount:0,
      userPoolAmount:0,
      getUserPoolLogLength:0,
      logs:[],
      restakestatus:true,
      restakeAmount:0,
      mininvest:0,
      stakeAmount:0
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      //setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = BillionsBNB.networks;
        const instance = new web3.eth.Contract(
          BillionsBNB.abi,
          deployedNetwork && BillionsBNB.address,
        );
        
        let user_account = accounts[0];
        // user_account = '0xEF6c22f5690BB69e3351232E7dd657f75Ed26CE1';
        
        this.setState({ web3, accounts:user_account, contract: instance, contractAddress:BillionsBNB.address}, this.fetchData);
        }
     //},1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false,loading:false});
      console.error(error);      
    }

    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    this.usdPriceUpdate();
  };

  onFocus = ()=> {
    //window.location.reload(false);
    // this.setState({loading:false});
    // this.fetchData();
    console.log('focus');
    if(window.innerWidth < 768){
      console.log('mobile focus');
    }
  }

  onBlur = ()=> {
    //window.location.reload(false);
    // this.setState({loading:false});
    // this.fetchData();
    console.log('blur');
    if(window.innerWidth < 768){
      console.log('mobile blur');
      this.setState({loading:false});
      this.fetchData();
    }
  }

  usdPriceUpdate = async() => {
    fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      this.setState({UsdToBnb:data.price});
    }
    
    );

    // trxusdapi(false).then((data)=>{
    //  this.setState({UsdToBnb:data})
    // }).catch(err => console.log('trxusdapi---' + err));
  }


  fetchData = async() => {
    const { accounts, contract } = this.state;

    //console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;

    let userInfo = await contract.methods.users(accounts).call();
    let user_referer = '---------';
    let sponsor_address = this.state.sponsor_address;
    if(userInfo.referrer && !userInfo.referrer.includes('0x000')){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      sponsor_address = userInfo.referrer;      
    }else{      
      sponsor_address = (this.props.match.params.referral != undefined) ? this.props.match.params.referral : '';
      if(sponsor_address == undefined){
        sponsor_address = '';
      }
    }
    

    let referrerBonus = this.state.referrerBonus;
    let restakestatus=true;
    if(userInfo.referrerBonus){
      referrerBonus = userInfo.referrerBonus;
      restakestatus=false;
      referrerBonus = (referrerBonus / 1e8);
    }

    // console.log('---------userInfo---------');
    // console.log(userInfo);

    let currentPrice = await contract.methods.buyPrice().call();
    currentPrice = this.state.web3.utils.fromWei(currentPrice, 'ether');

    let currentSellPrice = await contract.methods.sellPrice().call();
    currentSellPrice = this.state.web3.utils.fromWei(currentSellPrice, 'ether');

    let mininvest = await contract.methods.minAmount().call();
    // mininvest = this.state.web3.utils.fromWei(mininvest, 'ether');

    let userBalanceOf = await contract.methods.userBalanceOf(accounts).call();
    userBalanceOf = (userBalanceOf / 1e8);

    let poolStakes = await contract.methods.poolStakes(accounts).call();
    let restakeAmount = poolStakes.amount;
    restakeAmount = (restakeAmount / 1e8);
    

    let balance = await this.state.web3.eth.getBalance(accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');

    let availableStake = userBalanceOf;
    let availableStakeBnb = userBalanceOf/1e8;

    let staking = await contract.methods.getUserTotalStake(accounts).call();
    staking = (staking / 1e8);

    let totalTokenSupply = await contract.methods.totalTokenSupply().call();
    totalTokenSupply = (totalTokenSupply / 1e8);
    totalTokenSupply = parseFloat(totalTokenSupply).toFixed(2);  

    let per = ((totalTokenSupply / 100000000000000) * 100);
    per = parseFloat(per).toFixed(2);

    let current_timestamp = Math.floor(Date.now() / 1000);

    let systemPoolAmount = await contract.methods.systemPoolAmount().call();
    systemPoolAmount = systemPoolAmount/1e8;

    let getUserPoolAmount = await contract.methods.getUserPoolAmount(accounts).call();
    let userPoolAmount = getUserPoolAmount.amount/1e8;


    this.setState({
      userInfo,
      user_referer:user_referer,
      sponsor_address:sponsor_address,
      YourAddress,
      YourAddressfull,
      currentPrice,
      currentSellPrice,
      userBalanceOf,
      balance,
      balanceEthVal,
      availableStake,
      availableStakeBnb,
      staking,
      totalTokenSupply,
      per,
      referrerBonus,
      current_timestamp,
      systemPoolAmount,
      userPoolAmount,
      restakestatus,
      restakeAmount,
      mininvest,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      let getUserDetails = await contract.methods.getUserDetails(accounts).call();
      let table_length = parseInt(getUserDetails[0]);
      let table_data = [];
  
      for(let i=0;i<table_length;i++){
        let stakeOf = await contract.methods.stakeOf(accounts,i).call();
        let stake = stakeOf.amount/1e8;
        
  
        let roi = await contract.methods.getUserDividend(accounts,i).call();
        let roi_value = roi;
        roi = (roi /1e8);

        let userStake_start = stakeOf.starttime;
        let start_time = parseInt(userStake_start)*1000;
        let expiry_time = stakeOf.lockdate;
        let remainingDayTime = parseInt(expiry_time) - this.state.current_timestamp;
        let Total_reward =stake*1.5

        let roi_data = {index:i,stake:stake,roi:roi,userStake_start:userStake_start,Total_reward:Total_reward,expiry_time:expiry_time};
          table_data.push(roi_data);
      }
      
      this.setState({table_data});

      let sponsorList = [];
      let count = 0;
      for(let i=0;i<10;i++){
        let referral_stage = await contract.methods.referral_stage(accounts,i).call();
        let requiredDirect = await contract.methods.requiredDirect(i).call();
        let _investment = referral_stage._investment/1e18;
        let percentage_amount = referral_stage._bonus/1e8;
        _investment = parseFloat(_investment).toFixed(5);
        percentage_amount = parseFloat(percentage_amount).toFixed(5);
        let _noOfUser = referral_stage._noOfUser;
        
        if(i == 0){
          count = _noOfUser;
        }
        let status = '';
        let requireText = '';
        if(count<requiredDirect){
          status = 'Unqualified';
          requireText = 'require '+requiredDirect + ' direct';
        }
        sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      }
      
      this.setState({sponsorList});

      let logs = [];
      let getlogLength = await contract.methods.getlogLength(accounts).call();
      if(getlogLength>0){
        for(let i=0;i<getlogLength;i++){
          let log = await contract.methods.logs(accounts,i).call();
          let action = log.action;   
          let amount = log.amount/1e8 + ' BILB'
          if(action=='Buy Token'){
            amount = log.amount/1e18 +' BNB';
          }
          let start = log.time;
          console.log("contract",start)
          start = moment(start*1000).format('lll');
          console.log(start)   
          
          logs.push({amount:amount,action:action,start:start});
        }
      }
      
      
      this.setState({logs});
    })
    
  }

  doBuy= async () => {
    // console.log('do buy');
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    let inputbalance = this.state.web3.utils.toWei(this.state.amount.toString(), 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    
    
    if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }
    // console.log(inputbalance,this.state.mininves)
    if(parseInt(inputbalance)<parseInt(this.state.mininvest)){
      this.props.enqueueSnackbar("Min buy will be equal of 0.1 BNB",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }

    if(!this.state.amount){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amount;
    let TokenAmount = parseInt(this.state.TokenAmount);
    let get_polkabot_amount = parseInt(this.state.get_polkabot_amount);
    if(sponsor_address){
      //if(balanceEthVal >= amountBNB){
      if(get_polkabot_amount >= 100){
        try {
          this.setState({loading:true});
         

          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          let invest = await this.state.contract.methods.buyToken(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
          if(invest.status == true){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Buy Token Successfully!",{ variant: 'success' });                
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
          }
        }
        catch(err) {
          console.log(err);
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Minimum 100 Token is required!",{ variant: 'error' });          
      }

      // }else{
      //   this.setState({loading:false});
      //   this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
      // }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doSell= async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let TokenAmount = this.state.TokenAmount;
    let availableStake = this.state.availableStake;
    console.log(TokenAmount,availableStake);
    // let sponsor_address = this.state.sponsor_address;
    
    // if(!sponsor_address){
    //   this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
    //   this.setState({sponsor_addressError:true});
    //   return false;
    // }

    if(!this.state.TokenAmount){
      this.props.enqueueSnackbar("Token Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }

    if(!availableStake){
      this.props.enqueueSnackbar("Insufficient Token Balance!!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }    
    
    //if(sponsor_address){
      if(parseFloat(availableStake) > parseFloat(TokenAmount)){
        try {
          this.setState({loading:true});
         

          TokenAmount = (TokenAmount * 1e8).toString();
          let sell = await this.state.contract.methods.sellToken(TokenAmount).send(
            {
              from: this.state.accounts,
              value:0
            }
          );
          //console.log(sell);
          if(sell.status == true){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Sell Token Successfully!",{ variant: 'success' });                
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
          }
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Token Balance!",{ variant: 'error' });          
      }        
    // }else{
    //   this.setState({loading:false});
    //   this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    // }
  }

  

  doRedeem = async (index,expiry_time) => {
    let currentTime = Math.floor(Date.now() / 1000);
    try {
      this.setState({loading:true});
    
   
      let redeemReward = await this.state.contract.methods.redeemReward((index).toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(removeStake);
      if(redeemReward.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Stake Remove Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }
  

  doCalculation=(e,amount_type,type=null)=>{
    var x = new BigNumber(e.target.value);
    if(type == 'buy'){
      if(amount_type==0){
        this.setState({
          amount:e.target.value,
          totalAmount:(x.isNaN() ? 0 : x.multipliedBy(this.state.currentPrice))
        });
      }else{
        var y = new BigNumber(this.state.currentPrice);
        this.setState({
          amount:(x.isNaN() ? 0 : x.dividedBy(y)),
          totalAmount:(x.isNaN() ? 0 : x)
        });
      }
    }

    if(type == 'sell'){
      if(amount_type==0){
        this.setState({
          amount:e.target.value,
          totalAmount:(x.isNaN() ? 0 : x.multipliedBy(this.state.currentPrice))
        });
      }else{
        var y = new BigNumber(this.state.currentPrice);
        this.setState({
          amount:(x.isNaN() ? 0 : x.dividedBy(y)),
          totalAmount:(x.isNaN() ? 0 : x)
        });
      }
    }    
  } 

  doSwap = () => {
    if(this.state.isBuy){
      this.doBuy();
    }else{
      this.doSell();
    }
  }

  doCalculationApi = async(value,type) => {
    let amount = this.state.amount;    
    let TokenAmount = this.state.TokenAmount;
    console.log(type);
    if(type == 'amount'){
      console.log(amount);
      if(amount){
        amount = this.state.web3.utils.toWei(amount.toString(), 'ether');
        let calculateTokensReceived = await this.state.contract.methods.calculateTokensReceived(amount).call();
        console.log('get token');
        calculateTokensReceived = calculateTokensReceived/1e8;
        console.log(calculateTokensReceived);
        this.setState({TokenAmount:calculateTokensReceived});
      }else{
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' });
      }
      
    }else{
      console.log(TokenAmount);
      if(TokenAmount){
        let calculateBnbReceived = 0;
        if(this.state.totalTokenSupply > 0){
          TokenAmount = (TokenAmount * 1e8).toString();
          //TokenAmount = this.state.web3.utils.toWei(TokenAmount.toString(), 'ether');
          calculateBnbReceived = await this.state.contract.methods.calculateBnbReceived(TokenAmount).call();
          calculateBnbReceived = calculateBnbReceived/1e18;
        }      
        console.log('get bnb');
        console.log(calculateBnbReceived);        
        this.setState({amount:calculateBnbReceived});
      }else{
        this.props.enqueueSnackbar("Token Amount is required!",{ variant: 'error' });
      }
      
    }
  }

  getBillionsBNBApi = async(value,type) => {
    let amount = this.state.amount;
    if(amount){
      amount = this.state.web3.utils.toWei(amount.toString(), 'ether');
      let calculateTokensReceived = await this.state.contract.methods.calculateTokensReceived(amount).call();
      console.log('get token');
      calculateTokensReceived = calculateTokensReceived/1e8;
      console.log(calculateTokensReceived);
      this.setState({get_polkabot_amount:calculateTokensReceived});
    }else{
      this.setState({get_polkabot_amount:0});
    }
  }

  getBnbApi = async(value,type) => {
    let TokenAmount = this.state.TokenAmount;
    if(TokenAmount){
      let calculateBnbReceived = 0;
      if(this.state.totalTokenSupply > 0){
        TokenAmount = (TokenAmount * 1e8).toString();
        //TokenAmount = this.state.web3.utils.toWei(TokenAmount.toString(), 'ether');
        calculateBnbReceived = await this.state.contract.methods.calculateBnbReceived(TokenAmount).call();
        calculateBnbReceived = calculateBnbReceived/1e18;
      }
      console.log('get bnb');
      console.log(calculateBnbReceived);        
      this.setState({get_bnb_amount:calculateBnbReceived});
    }else{
      this.setState({get_bnb_amount:0});
    }
  }

  doTransfer= async () => {
    try {
      this.setState({loading:true});
     

      let withdrawReward = await this.state.contract.methods.withdrawReward(this.state.accounts).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(withdrawReward);
      if(withdrawReward.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Withdraw Reward Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }

  doRestake= async () => {
    if(!this.state.referrerBonus){
      this.props.enqueueSnackbar("you don't have funds",{ variant: 'error' });
      return false;
    }

    try {
      this.setState({loading:true});
     

      let restake = await this.state.contract.methods.createPoolStake().send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(withdrawReward);
      if(restake.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Withdraw Reward Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }


  dostake= async () => {
    if(!this.state.stakeAmount){
      this.props.enqueueSnackbar("Please Enter Amount",{ variant: 'error' });
      return false;
    }

    if(parseInt(this.state.stakeAmount)>parseInt(this.state.userBalanceOf)){
      this.props.enqueueSnackbar("You Don't have Funds",{ variant: 'error' });
      return false;
    }

    try {
      this.setState({loading:true});
     
      let value = this.state.stakeAmount*1e8;

      let stake = await this.state.contract.methods.stake(value.toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(withdrawReward);
      if(stake.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Stake Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }

  doPoolUnstake= async () => {
    if(!this.state.restakeAmount){
      this.props.enqueueSnackbar("you don't have funds",{ variant: 'error' });
      return false;
    }

    try {
      this.setState({loading:true});
     

      let removestake = await this.state.contract.methods.removePoolStake().send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(withdrawReward);
      if(removestake.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Withdraw Reward Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }

  doClaim= async () => {
    if(!parseFloat(this.state.userPoolAmount)){
      this.props.enqueueSnackbar("Pool Amount is insufficient!",{ variant: 'error' });
      return false;
    }
    try {
      this.setState({loading:true});     

      let claimPoolReward = await this.state.contract.methods.claimPoolReward().send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      if(claimPoolReward.status == true){
        this.fetchData();
        this.setState({loading:false});
        this.props.enqueueSnackbar("Claim Reward Successfully!",{ variant: 'success' });                
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Something Went Wrong",{ variant: 'error' });   
      }
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }


    render() {
      const { t } = this.props;
      return (
        <Frame withHeader={false} withFooter={true} currentPrice={this.state.currentPrice}>
          <TopHeader isHome={true} address={this.state.accounts} currentPrice={this.state.currentPrice} />
          {this.state.loading ? (
            <div className="loader-container">
              <span className="loader"> </span>
            </div>
          ) : (null)}


          <div className="modal fade" id="Modal_popup" style={{display:'none'}}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
              <button className="modal_close" type="button" data-bs-dismiss="modal">
                <img className="modal_close_img" src="img/Close-icon.png" alt="" />
              </button>
              <div className="modal_head text-center">
                <img src="img/logo.png" alt="" />
              </div>
                <div className="modal-body">
                  <div className="all_heading sm_all_heading mb-4">
                    <h2>{t('mainContent.Popup_title1')} <span>{t('mainContent.Popup_title2')}</span></h2>
                    <p>{t('mainContent.Popup_desc')}</p>
                  </div>
                  <p><strong>{t('mainContent.Popup_subtitle1')} </strong></p>
                  <p>{t('mainContent.Popup_desc1')}</p>
                  <ul className="about_ul">
                    <li>{t('mainContent.Popup_li1')}</li>
                    <li>{t('mainContent.Popup_li2')}</li>
                    <li>{t('mainContent.Popup_li3')}</li>
                    <li>{t('mainContent.Popup_li4')}</li>
                    <li>{t('mainContent.Popup_li5')}</li>
                  </ul>
                  <p><strong>{t('mainContent.Popup_subtitle2')}</strong></p>
                  <p>{t('mainContent.Popup_p21')}</p>
                  <p>{t('mainContent.Popup_p22')}</p>
                  <p>{t('mainContent.Popup_p23')}</p>
                  <p>{t('mainContent.Popup_p24')}</p>
                  <p>{t('mainContent.Popup_p25')}</p>
                  <p><strong>{t('mainContent.Popup_subtitle3')}</strong></p>
                  <p>{t('mainContent.Popup_p31')}</p>
                  <p>{t('mainContent.Popup_p32')}</p>
                  <p>{t('mainContent.Popup_p33')}</p>
                  <p>{t('mainContent.Popup_p34')}</p>
                  <p>{t('mainContent.Popup_p35')}</p>
                  <p>{t('mainContent.Popup_p36')}</p>
                  <p>{t('mainContent.Popup_p37')}</p>
                  <p>{t('mainContent.Popup_p38')}</p>
                  <p>{t('mainContent.Popup_p39')}</p>
                  <p><strong>{t('mainContent.Popup_subtitle4')}</strong></p>
                  <p><strong>{t('mainContent.Popup_table_heading')}</strong></p>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>{t('mainContent.Popup_td11')}</th>
                        <td>{t('mainContent.Popup_td12')}</td>
                      </tr>
                      <tr>
                        <th>{t('mainContent.Popup_td21')}</th>
                        <td>{t('mainContent.Popup_td22')}</td>
                      </tr>
                      <tr>
                        <th>{t('mainContent.Popup_td31')}</th>
                        <td>{t('mainContent.Popup_td32')}</td>
                      </tr>
                      <tr>
                        <th>{t('mainContent.Popup_td41')}</th>
                        <td>{t('mainContent.Popup_td42')}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>{t('mainContent.Popup_p41')}</p>
                  <p>{t('mainContent.Popup_p42')}</p>
                  <p>{t('mainContent.Popup_p43')}</p>
                  <p>{t('mainContent.Popup_p44')}</p>
                  <p><strong>{t('mainContent.Popup_subtitle5')}</strong></p>
                  <p>{t('mainContent.Popup_p51')}</p>
                  <p>{t('mainContent.Popup_p52')}</p>
                  
                  <div className="all_heading sm_all_heading mb-4">
                    <h2><span>{t('mainContent.Popup_Roadmap_heading')}</span></h2>
                  </div>
                  
                  <p><strong>{t('mainContent.Popup_Roadmap_date1')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title1')}</p>
                  <p><strong>{t('mainContent.Popup_Roadmap_date2')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title2')}</p>
                  <p><strong>{t('mainContent.Popup_Roadmap_date3')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title3')}</p>
                  <p><strong>{t('mainContent.Popup_Roadmap_date4')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title4')}</p>
                  <p><strong>{t('mainContent.Popup_Roadmap_date5')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title5')}</p>
                  <p><strong>{t('mainContent.Popup_Roadmap_date6')}</strong></p>
                  <p>{t('mainContent.Popup_Roadmap_title6')}</p>
                  <p>{t('mainContent.Popup_Roadmap_title62')}</p>

                </div>
              </div>
            </div>
          </div>
          
            <section className="main-banner-area">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner_text">
                    
                    <h1> 
                      <span className="typewrite" data-period="2000" data-type={`[' ${t('mainContent.Polkabot')}' ]`}>
                        <span className="wrap">{t('mainContent.Polkabot')}</span>
                      </span>
                      {t('mainContent.Expect_the_unexpected')}
                      
                    </h1>
                    <p>{t('mainContent.Banner_Desc')}</p>
                    <a className="buy_btn" href={`https://bscscan.com/address/${BillionsBNB.address}`} target="_blank">{t('mainContent.View_On_BscScan')}</a>
                    
                  </div>
                </div>
               
              </div>
              
            </div>
            
          </section>

          <section className="pt-5 pb-5">
            <div className="container">
              <div className="row g-5">
                <div className="col-md-6 col-sm-6">
                  <div className="all_heading mb-4">
                    <h2>{t('mainContent.What_is')} <span>{t('mainContent.Polkabot')}?</span></h2>
                    <p>{t('mainContent.Refer_Desc')}</p>
                  </div>
                  <ul className="about_ul">
                    <li>{t('mainContent.Refer_li1')}</li>
                    <li>{t('mainContent.Refer_li2')}</li>
                    <li>{t('mainContent.Refer_li3')}</li>
                    <li>{t('mainContent.Refer_li4')}</li>
                    <li>{t('mainContent.Refer_li5')}</li>
                    <li>{t('mainContent.Refer_li6')}</li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className='refer_bg about_box'>
                    <img className='img-fluid' src="img/financial-3.png" alt="" />
                    <div className="video-box">
                      <div className="video-main">
                        <div className="promo-video">
                          <div className="waves-block">
                            <div className="waves wave-1"></div>
                            <div className="waves wave-2"></div>
                            <div className="waves wave-3"></div>
                          </div>
                        </div>
                        <a href="#" target="_blank" className="video"><i className="fa fa-play"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="modal fade" id="myModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" data-bs-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    {this.state.isBuy ? (
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group mb-2">
                          <label className="white_label">{t('mainContent.Polkabot')} {t('mainContent.Referral')}</label>
                          <input className="cus_input" type="text" placeholder={`${t('mainContent.Referral_Address')}`}
                          value={this.state.sponsor_address || ''}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}  />
                        </div>
                        <p>{t('mainContent.Polkabot')} {t('mainContent.Received')}: {parseFloat(this.state.get_polkabot_amount).toFixed(7)}</p>
                        <div className="form-group" style={{float:'right'}}>
                          <button className="buy_btn" onClick={this.doSwap} data-dismiss="modal" data-bs-dismiss="modal">{t('mainContent.Submit')}</button>
                        </div>                        
                      </div>
                    ) : (
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <p>{t('mainContent.BNB')} {t('mainContent.Received')}: {parseFloat(this.state.get_bnb_amount).toFixed(7)}</p>

                        <div className="form-group" style={{float:'right'}}>
                          <button className="buy_btn" onClick={this.doSwap} data-dismiss="modal" data-bs-dismiss="modal">{t('mainContent.Submit')}</button>
                        </div>                        
                      </div>
                    )}
                  </div>
                </div>            
              </div>
            </div>     
          </div>
      

          <section className="pb-5">
            <div className="container">
              <div className="md_container">
                <div className="row g-3">
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="mywallet_box">
                      <div className="mywallet_heading">
                        <h3>
                          {t('mainContent.My_Wallet')}
                        </h3>
                      </div>
                      <div className="mywallet_body text-center">
                          <h2 className="my_ballance_amount">
                            <img src="img/icon.png" alt="" />{parseFloat(this.state.availableStake).toFixed(7)}
                          </h2>
                          <p className="smwallet_text">{parseFloat(this.state.availableStake*this.state.currentSellPrice).toFixed(7)} {t('mainContent.BNB')}
                            (${parseFloat(this.state.availableStake*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)})
                          </p>
                         
                      </div>
                      <div className="mywallet_footer">
                            <div className='row'>
                              <div className="col-md-8 col-sm-6 col-lg-8">
                                  <div className="">
                                  <input className="cus_input" type="number" placeholder='Amount'
                                  value={this.state.stakeAmount || ''}
                                  onChange={(e)=>this.setState({stakeAmount:e.target.value})}  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-lg-4">
                                <div>
                                  <button disabled={this.state.loading} className="buy_btn" onClick={this.dostake}>Stake</button>
                                </div>   
                              </div>
                            </div>
                          </div>
                    </div>
                  </div> <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="mywallet_box">
                      <div className="mywallet_heading">
                      <h3>
                          {t('mainContent.My_Rewards')}
                        </h3>
                        
                      </div>
                      <div className="mywallet_body text-center">
                        <div className="row g-2">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                          <h2 className="my_ballance_amount">
                              <img src="img/icon.png" alt="" />{parseFloat(this.state.referrerBonus).toFixed(7)}
                            </h2>
                            <p className="smwallet_text">{t('mainContent.From_Referrals')}
                              {/* {parseFloat(this.state.referrerBonus*this.state.currentSellPrice).toFixed(7)} BNB 
                              (${parseFloat(this.state.referrerBonus*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)}) */}
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      <div className="mywallet_footer">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                            <a disabled={this.state.loading} onClick={this.doTransfer} className="reinvestmeny_btn buy_button slow_link">{t('mainContent.WITHDRAW')}</a>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                            <a disabled={this.state.restakestatus} className="reinvestmeny_btn buy_btn"onClick={this.doRestake}>{t('mainContent.Re-Stake')}</a>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="mywallet_box">
                      <div className="mywallet_heading">
                       
                      </div>
                      <div className="mywallet_body text-center">
                        <div className="row g-2">
                          <div className="col-md-6 col-sm-6 col-lg-6">
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-6">
                           
                            <p className="smwallet_text">{t('mainContent.From_Referrals')}
                              {parseFloat(this.state.referrerBonus*this.state.currentSellPrice).toFixed(7)} BNB 
                              (${parseFloat(this.state.referrerBonus*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)})
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      <div className="mywallet_footer">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                            <a disabled={this.state.loading} onClick={this.doTransfer} className="reinvestmeny_btn buy_button slow_link">{t('mainContent.WITHDRAW')}</a>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                            <a disabled={this.state.restakestatus} className="reinvestmeny_btn buy_btn"onClick={this.doRestake}>Re-Stake</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row g-3">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="mywallet_box">
                        <div className="mywallet_heading">
                          <h3>
                            <span className="text-success">$</span>{t('mainContent.BUY')} {t('mainContent.Polkabot')}
                          </h3>
                        </div>
                        <div className="mywallet_body text-center">
                          <img className="buy_sele_logo" src="img/bnb_coin.png" alt="" />
                            <h2 className="my_ballance_amount">
                             {parseFloat(this.state.currentPrice).toFixed(7)}
                            </h2>
                            <p className="smwallet_text">${parseFloat(this.state.currentPrice*this.state.UsdToBnb).toFixed(2)}</p>
                           <div className="mt-4">
                            <input placeholder={`${t('mainContent.BNB_Spend')}`} className="cus_input" type="number"
                              value={this.state.amount || ''}
                              onChange={(e)=>this.setState({amount:e.target.value})}
                              onBlur={(e)=>this.getBillionsBNBApi(e.target.value,'amount')}
                            />
                           </div>
                        </div>
                        <div className="mywallet_footer">
                          <button data-toggle="modal" data-target="#myModal" data-bs-toggle="modal" data-bs-target="#myModal" onClick={()=>this.setState({isBuy:true})} className="buy_btn buy_button btn-block">{t('mainContent.BUY')}</button>
                          {/* <p className="smwallet_text text-center">
                            &nbsp;
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="mywallet_box">
                        <div className="mywallet_heading">
                          <h3>
                            <span className="text-danger">$</span> {t('mainContent.Sell')} {t('mainContent.Polkabot')}
                          </h3>
                        </div>
                        <div className="mywallet_body text-center">
                          <img className="buy_sele_logo" src="img/bnb_coin.png" alt="" />
                            <h2 className="my_ballance_amount">
                            {parseFloat(this.state.currentSellPrice).toFixed(7)}
                            </h2>
                            <p className="smwallet_text">${parseFloat(this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)}</p>
                           <div className="mt-4">
                            <input placeholder={`${t('mainContent.POLKA_Sell')}`} className="cus_input" type="number"
                              value={this.state.TokenAmount || ''}
                              onChange={(e)=>this.setState({TokenAmount:e.target.value})}
                              onBlur={(e)=>this.getBnbApi(e.target.value,'token')}
                            />
                           </div>
                        </div>
                        <div className="mywallet_footer">
                          <button data-toggle="modal" data-target="#myModal" data-bs-toggle="modal" data-bs-target="#myModal" onClick={()=>this.setState({isBuy:false})} className="buy_btn sell_button btn-block">{t('mainContent.SELL')}</button>
                          {/* <p className="smwallet_text text-center">
                            Admin Fee 5%
                          </p> */}
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pb-5">
            <div className="container">
              <div className="md_container">
                <div className="row g-3">
                <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="mywallet_box">
                      <div className="mywallet_heading">
                        <h3>{t('mainContent.Re-Stake-Deatils')}</h3>
                        
                      </div>
                      <div className="mywallet_body text-center">
                        <div className="row g-2">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <h2 className="my_ballance_amount">
                              <img src="img/icon.png" alt="" />{parseFloat(this.state.restakeAmount).toFixed(4)}
                            </h2>
                            <p className="smwallet_text">{t('mainContent.Restake-Amount')}</p>
                          </div>
                        </div>
                        
                      </div>
                      <div className="mywallet_footer">
                        {parseFloat(this.state.restakeAmount)>0?(
                          <button className="buy_btn sell_button btn-block" disabled={this.state.loading} onClick={this.doPoolUnstake}>{t('mainContent.Unstake')}</button>
                        ):(
                          <button className="buy_btn sell_button  btn-block" disabled={true}>{t('mainContent.Unstake')}</button>
                        )}
                      </div>
                     
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="mywallet_box">
                      <div className="mywallet_heading">
                        <h3>{t('mainContent.POOL-Details')}</h3>
                      </div>
                      <div className="mywallet_body text-center">
                        <div className="row g-2">
                          <div className="col-md-6 col-sm-6 col-lg-6">
                          <h2 className="my_ballance_amount">
                            <img src="img/icon.png" alt="" />{parseFloat(this.state.systemPoolAmount).toFixed(4)}
                          </h2>
                            <p className="smwallet_text">{t('mainContent.System-Pool-Amount')}</p>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-6">
                          <h2 className="my_ballance_amount">
                              <img src="img/icon.png" alt="" />{parseFloat(this.state.userPoolAmount).toFixed(4)}
                            </h2>
                            <p className="smwallet_text">{t('mainContent.User-Pool-Amount')}
                              {/* {parseFloat(this.state.referrerBonus*this.state.currentSellPrice).toFixed(7)} BNB 
                              (${parseFloat(this.state.referrerBonus*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)}) */}
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      <div className="mywallet_footer">
                        {parseFloat(this.state.userPoolAmount)>0?(
                          <button className="buy_btn btn-block" disabled={this.state.loading} onClick={this.doClaim}>{t('mainContent.Claim')}</button>
                        ):(
                          <button className="buy_btn btn-block" disabled={true}>{t('mainContent.Claim')}</button>
                        )}
                      </div>
                    </div>
                  </div>
                 
                </div>
                
              </div>
            </div>
          </section>


          <section className="pb-5" id="ROI_table">
            <div className="container">
              <div className="md_container">
                <div className="white_box">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th><img className="table_coin" src="img/icon.png" alt="" />{t('mainContent.Stake')}</th>
                          <th><img className="table_coin" src="img/icon.png" alt="" />{t('mainContent.Reward')}</th>
                          <th>{t('mainContent.Redeem-Reward')}</th>
                          <th>{t('mainContent.Total-Receivable')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {                      
                          this.state.table_data.length ? (                        
                            this.state.table_data.length>0 ? (
                              this.state.table_data.map((val, index)=>{                              
                        
                              return (
                                <tr key={`cl${index}`}>
                                  <td>{parseFloat(val.stake).toFixed(2)}</td>
                                  <td>{parseFloat(val.roi).toFixed(2)}</td>
                                  <td><button  type="button" className="btn btn-outline-success btn-sm btn-block" onClick={()=>this.doRedeem(val.index,val.expiry_time)}>{t('mainContent.Redeem')}</button></td>
                                  <td>{parseFloat(val.Total_reward).toFixed(2)}</td>
                                </tr>
                              )
                            })                          
                            ):(null)
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                          </tr>
                        )}                   
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>            
            </div>
          </section>


          <section>
            <div className="container">
              <div className="refer_bg">
                    <div className="text-center">
                      <div className="refer-and-earn-box">
                        <h4 className="mt-0">{t('mainContent.Your_Wallet')}</h4>
                        <h2>{this.state.YourAddress} </h2>
                      
                          <CopyToClipboard text={`https://fairroi.org/${this.state.YourAddressfull}`}
                            onCopy={() => this.props.enqueueSnackbar(`${t('mainContent.Copied_Successfully')}`,{ variant: 'info' })
                            }>
                              <button className="buy_btn">
                            <span>{t('mainContent.Copy_referral_link')}</span></button>
                          </CopyToClipboard>
                        
                      </div>
                      
                     
                      {/* <div className="wallet_box bg2">
                        <h2>{parseFloat(this.state.balanceEthVal).toFixed(5)} BNB/ {this.state.userBalanceOf} BillionsBNB</h2>
                        <p>Your Balance</p>
                      </div> */}
                    </div>
                    {/* <div className="col-md-6 col-sm-6">
                      <div className="wallet_box bg3 wallet_box_m_t">
                        <h2>{this.state.availableStake}</h2>
                        <p>Available for Stake</p>
                        <button disabled={this.state.loading} onClick={this.doStake} type="button" className="btn btn-outline-success btn-sm">Stake</button>
                      </div>
                      <div className="wallet_box bg4">
                        <h2>{this.state.staking}</h2>
                        <p>Staking</p>
                      </div>
                    </div> */}
                </div>          
            </div>
          </section>

          <section className="pt-5 pb-5 about_area">
            <div className="container">
              <div className="">
                <h4>{t('mainContent.My_Sponsor_List')}</h4>
                <div className="white_box">                  
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>{t('mainContent.Level')}</th>
                          <th>{t('mainContent.Count')}</th>
                          <th>Total Investment</th>
                          <th>{t('mainContent.Bonus_Token')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {                      
                          this.state.sponsorList.length ? (
                            this.state.sponsorList.length>0 ? (
                              this.state.sponsorList.map((val, index)=>{
                              let class_name = 'lebel_'+val.index;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                        
                              return (
                                <tr key={`sl${index}`} className={class_name}>
                                  <td>{t('mainContent.Level')}-{val.level} <span style={{color:'#019dea'}}>{val.requireText?(`(${val.requireText})`):(null)}</span></td>
                                  <td>{val._noOfUser}</td>
                                  <td>{val._investment} BNB</td>
                                  <td>{val.percentage_amount} {t('mainContent.BILB')}</td>
                                </tr>
                              )
                            })
                            ):(null)
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                          </tr>
                        )}                   
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <h4>{t('mainContent.History')}</h4>
                <div className="white_box">                  
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>{t('mainContent.Action')}</th>
                          <th>{t('mainContent.Amount')}</th>
                          <th>{t('mainContent.Time')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {                      
                          this.state.logs.length ? (
                            this.state.logs.length>0 ? (
                              this.state.logs.map((val, index)=>{
                             
                              return (
                                <tr key={`pl${index}`}>
                                  <td>{val.action}</td>
                                  <td>{val.amount}</td>
                                  <td>{val.start}</td>
                                </tr>
                              )
                            })
                            ):(null)
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                          </tr>
                        )}                   
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
           
          </section>
      
         

          {/* <section className="pt-5 pb-5">
            <div className="container">
              <div className="all_heading heading_m mb-5 text-center">
                <h2>{t('mainContent.Roadmap_heading')}</h2>
                <p>{t('mainContent.Roadmap_desc')}</p>
              </div>
              <ul className="timeline">
                <li>
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date1')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title1')}</div>
                  </div>
                </li>                
                <li>
                  <div className="direction-l">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date2')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title2')}</div>
                  </div>
                </li>              
                <li>
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date3')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title3')}</div>
                  </div>
                </li>              
                <li>
                  <div className="direction-l">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date4')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title4')}</div>
                  </div>
                </li>
                <li>
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date5')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title5')}</div>
                  </div>
                </li>              
                <li>
                  <div className="direction-l">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date6')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title6')}</div>
                    <div className="desc">{t('mainContent.Roadmap_title62')}</div>
                  </div>
                </li>
                <li>
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <span className="flag">{t('mainContent.Roadmap_date7')}</span>
                      <span className="time-wrapper"></span>
                    </div>
                    <div className="desc">{t('mainContent.Roadmap_title7')}</div>
                  </div>
                </li>
              </ul>  

            </div>           
          </section> */}

       
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }
  
  //export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Home))));